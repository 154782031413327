import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Title, Button, Section, Box, Text } from "../../components/Core";
import { rgba } from "polished";

const StrongText = styled.span`
  position: relative;
  color: #1a1a1a;
  font-size: 28px;
  @media screen and (max-width: 767px) {
    font-size: 24px;
  }
  &:before {
    background-color: #00aec7;
    content: "";
    position: absolute;
    width: calc(100% + 4px);
    height: 40%;
    left: -2px;
    bottom: 0;
    z-index: -1;
    transform: rotate(-2deg);
  }
`;

const StyledCounterSection = styled.div`
  display: flex;
  margin: 0px auto;
  min-height: 100vh;
  max-width: 450px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > * {
    width: 100%;
  }

  h2 {
    color: #1a1a1a;
    margin: 0;
    margin-bottom: 0px;
    font-size: 20px;
    padding: 0;
    text-align: center;
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
`;

const CounterSection = () => {
  const [treatmentsCounter, setTreatmentsCounter] = useState(0);
  const [experienceCounter, setExperienceCounter] = useState(0);
  const [staffCounter, setStaffCounter] = useState(0);
  const counterRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Trigger when 50% of the element is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const interval = setInterval(() => {
            setTreatmentsCounter((prev) => (prev < 5000 ? prev + 60 : prev));
          }, 10);
          return () => clearInterval(interval);
        }
      });
    }, options);

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => observer.disconnect();
  }, [counterRef]);

  // Use formattedTreatmentsCounter where you want to display the formatted number
  const formattedTreatmentsCounter = treatmentsCounter.toLocaleString();

  return (
    <StyledCounterSection ref={counterRef}>
      <h2>
        <StrongText> {formattedTreatmentsCounter}+</StrongText> Treatments Performed
      </h2>
    </StyledCounterSection>
  );
};

export default CounterSection;
