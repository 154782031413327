import React from "react";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import CounterSati from '../../shared/nose/noserev.js';
import CounterRev from '../../shared/Rev.js';
import CounterYears from '../../shared/Sati.js';


import Counts from '../../shared/nose/nosetreat.js';
import { Title, Button, Section, Box, Text } from "../../components/Core";
import styled from "styled-components";



const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const FeatureCard = ({
  color = "none",
  title,
  children,
  ...rest
}) => (
  <Box width={"100%"} bg="none" p="5px" borderRadius={10} {...rest}>
    <div className="d-flex justify-content-left align-items-center">
      <Box
        size={69}
        minWidth="100%"
        minHeight={69}
        borderRadius="50%"
        color={color}
        fontSize="28px"
        className="d-flex justify-content-left align-items-center"
       
        css={`
      
            @media screen and (max-width: 767px)
            {
          
                font-size:17px;
             }
        `}
      >
     {children}
      </Box>
      
    </div>
  </Box>
);

const Feature = () => (
  <>
  <Separator />
    <Section py={4}  className="pb-md-5 mb pt-md-5" bg="#f7f7fb">
      <Container className="pt-4 pb-0">
        <Row className="align-items-center">
          <Col
            md="8"
            lg="6"
            className="offset-lg-1 pl-lg-5 order-lg-2 pb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="500"
          >
            
             <Iwrap>


<Itext as="h2">Non Surginal Nose Job <span>by the numbers</span></Itext>
</Iwrap>
      <SecondText>
   A Clinic You Can Trust
      </SecondText>
              
           
          </Col>
          <Col md="8" lg="5" className="order-lg-1 mt-2 mt-lg-0">
            <div>
              <Row className="justify-content-center">
                <Col
                  md="12"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="50"
                >
                  <FeatureCard
                
                  >
                   <CounterSati />
                   <Counts />
                  </FeatureCard>
                </Col>
                <Col
                  md="12"
                  className="mt-lg-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="50"
                >
                  <FeatureCard
                    
                  >
                  <Counts />
                  </FeatureCard>
                </Col>
                <Col
                  md="12"
                  className="mt-lg-4"
                  data-aos="fade-up"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="50"
                >
                  <FeatureCard
                   
                  >
                   <CounterYears />
                  </FeatureCard>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
    <SeparatorEnd />
  </>
);

export default Feature;
