import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Title, Button, Section, Box, Text } from "../components/Core";
import { rgba } from "polished";


const StyledCounterSection = styled.div`
  text-align: left;
  margin: 0px 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: left;

  .counter {
  display:block;
    align-items: left;
  }

  .counter h2 {
    font-size: 1.4rem; /* Adjust the font size */
    margin: 5px 0;
    background-color: ; /* Shade of white background color */
    color: #333; /* Text color */
    border-radius: 5px; /* Border radius of 5 */
    padding: 10px; /* Add some padding for a better appearance */
    width: 100px; /* Set a fixed width for uniformity */
  }

  .counter p {
    font-size: 1.1rem; /* Adjust the font size */
    margin: 0;
    text-align:left;
  }

  .counter:nth-child(2) h2 {
    background-color: #f9f9f9; /* Another shade of white for the second counter */
  }

  .counter:nth-child(3) h2 {
    background-color: #f9f9f9; /* Another shade of white for the third counter */
  }

  @media (max-width: 767px) {
    .counter p {
      font-size: 0.9rem; /* Adjust the font size */
      margin: 0;
      text-align:left;
    }
  }
`;

const CounterSection = () => {
  const [treatmentsCounter, setTreatmentsCounter] = useState(0);
  const [experienceCounter, setExperienceCounter] = useState(0);
  const [staffCounter, setStaffCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTreatmentsCounter((prev) => (prev < 1 ? prev + 1 : prev));
    }, 100);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setExperienceCounter((prev) => (prev < 18 ? prev + 1 : prev));
    }, 100);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setStaffCounter((prev) => (prev < 5 ? prev + 1 : prev));
    }, 130);
    return () => clearInterval(interval);
  }, []);

  return (
   
     

      
    
 
       <>{treatmentsCounter}k+
       
       </>
    

  );
};

export default CounterSection;
