import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import imgPhone from "../../assets/image/jpeg/tattoo1.jpg";

const SectionStyled = styled(Section)`
background: #f7f7fb;
`;

const WidgetContainer = styled(Box)`
  counter-reset: widget-counter;
`;

const WidgetStyled = styled(Box)`
  padding-left: 70px;
  position: relative;
  max-width: 411px;
  &:before {
    counter-increment: widget-counter;
    content: counter(widget-counter);
    position: absolute;
    left: 0;
    min-width: 49px;
    width: 49px;
    height: 49px;
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 500px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.light};
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.75px;
  }
`;

const Widget = ({ num = 1, title = "", children = "", ...rest }) => {
  return (
    <WidgetStyled {...rest}>
      <Title variant="card" mb={2}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </WidgetStyled>
  );
};

const Content1 = () => {
  return (
    <>
      {/* <!-- Content1 section --> */}
      <SectionStyled>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="mb-4 mb-lg-0 pl-lg-5 order-lg-2">
              <div
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <img src={imgPhone} alt="" className="img-fluid" />
              </div>
            </Col>
            <Col lg="6" md={9} className="order-lg-1">
              <div>
                <Title>
                  Our Experience
                  <br className="d-none d-sm-block d-md-none d-xl-block" /> Matters
                </Title>
                <Text>
                Dermamina stands out as one of the most active laser tattoo removal clinics in London, UK. While being thoroughly trained for tattoo removal is essential, our expertise goes beyond mere training. It's the knowledge gained from extensive experience in performing numerous laser tattoo removal treatments that truly sets us apart. <br /> <br />The nuanced understanding of optimal laser parameters for specific tattoo removal isn't acquired in a training course but through years of hands-on experience.
                <br /> <br />
When you schedule an appointment with Dermamina, you'll consistently meet with a highly seasoned consultant, ensuring you always receive the most effective and personalized treatments.
                </Text>
            
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Content1;
